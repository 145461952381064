const WalkThroughData = [
  {
    id: 1,
    cover: require("../../Assets/Walkthrough/gallery4.jpg"),
    alttag: "Eden wave city",
  },
  {
    id: 2,
    cover: require("../../Assets/Walkthrough/gallery2.jpg"),
    alttag: "Eden wave city",
  },
  {
    id: 3,
    cover: require("../../Assets/Walkthrough/gallery3.jpg"),
    alttag: "Eden wave city",
  },
  
];

export default WalkThroughData;
